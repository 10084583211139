import {React,useState,useRef} from 'react';
import '../Scan.css';
import inst_video from '../video/inst_video.webm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Scan() {
    const [hideVideo,sethideVideo] =  useState(false);
    const [hideSkip,sethideSkip] = useState(false);
    const [hideActionBtn,setActionBtn] = useState(true);
    const vidRef = useRef(null);
    const handleStopVideo = ()=>{
        vidRef.current.pause();
    }

    // Skip Video
    function skipVideo() {
        sethideVideo(true);
        sethideSkip(true);
        setActionBtn(false);
        handleStopVideo();
    }

  const queryParams = new URLSearchParams(window.location.search);
  const patientID = queryParams.get('id');
  const parentToken = queryParams.get('token');
  const iMedicToken = 'Token ' + parentToken;

  const heartRateRef = useRef();
  const spo2 = useRef();
  const respRateRef = useRef();
  const bsrateRef = useRef();
  const cholRateRef = useRef();
  const bloodPressureRateRef = useRef();
  const healthResultRef = useRef();


  function onUploadHealthReadings() {

    if (healthResultRef.current.value !== '') {
      const bloodPressureRateRefValue = bloodPressureRateRef.current.value.split(/[/]/);

      // const cholestrolConversionValue = 0.0555;
      // const cholRateRefValue = cholRateRef.current.value;
      // const cholestrolConversionValueConvert = cholRateRefValue * cholestrolConversionValue;
      // const cholestrolConvertedValue = Math.floor(cholestrolConversionValueConvert * 100) / 100;

      const date = new Date();
      const isoFormatDate = date.toISOString();

      var jsonData = {
        "log_time": isoFormatDate,
        "manual_mode": true,
        "patient": patientID,
        "data": [
          {
            "value": heartRateRef.current.value,
            "parameter": 4
          },
          {
            "value": spo2.current.value,
            "parameter": 5
          },
          {
            "value": respRateRef.current.value,
            "parameter": 871
          },
          {
            "value": bloodPressureRateRefValue[0],
            "parameter": 2
          },
          {
            "value": bloodPressureRateRefValue[1],
            "parameter": 3
          },
          {
            "value": bsrateRef.current.value,
            "parameter": 6
          },
          {
            "value": cholRateRef.current.value,
            "parameter": 382
          }
        ]
      };

      toast.success('Readings Uploaded Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Send data to the backend via POST
      fetch('https://sg-test.imedichealth.net/api/readings/', {

        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': iMedicToken
        },
        body: JSON.stringify(jsonData) // body data type must match "Content-Type" header

      })
        .then((response) => {
          if (response.status === 201) {
            // toast.success('Readings Uploaded Successfully', {
            //   position: "top-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            // });
          } else if (response.status === 401) {
            toast.error('Invalid Authentication', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          toast.error('Something went wrong, try again later', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error('Please start and wait a while, Face scanning not completed', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

  }

  return (
    <div className='scan'>
      {/* <h2 id="checker">Modules Loading</h2> */}
      <p className='message-scan' hidden>FPS : <span id='fps_msg'></span></p>
      <video hidden id="video" width="640" height="480"> </video>
      {/* <div className='skip__BtnCont'>
        <button id='skip_btn' onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
      </div> */}
      <div className="scan__canvasContainer">
        <div className='progress__container'>
          <div id="progress__value"></div>
        </div>
        <video ref={vidRef} loop muted autoPlay controls hidden={hideVideo} >
            <source src={inst_video} type="video/webm"></source>
        </video>
        <canvas id="canvas" width="640" height="480"></canvas>
        <div className="scan__vitalBox">
          {/* Basic Module elements */}
          <div className='card-left'>
            <div className="scan__Box">
              <label htmlFor='heart_rate'>Heart Rate</label><br />
              <input ref={heartRateRef} type="text" id="heart_rate" readOnly />
              <span className="unit_value">bpm</span>
            </div>
            <div className="scan__Box">
              <label htmlFor='resp_rate'>Respiratory Rate</label><br />
              <input ref={respRateRef} type="text" id="resp_rate" readOnly />
              <span className="unit_value">1/min</span>
            </div>
            <div className="scan__Box">
              <label htmlFor='spo2'>SpO2 Level</label><br />
              <input ref={spo2} type="text" id="spo2" readOnly />
              <span className="unit_value">%</span>
            </div>
          </div>
          <div className='card-right'>
            {/* Blood Pressure Module Elements */}
            <div className="scan__Box">
              <label htmlFor='blood_pressure'>Blood Pressure</label><br />
              <input ref={bloodPressureRateRef} type="text" id="blood_pressure" readOnly />
              <span className="unit_value">mmHg</span>
            </div>
            {/* Blood Sugar Module Elements */}
            <div className="scan__Box">
              <label htmlFor='bs_rate'>Blood Sugar</label><br />
              <input ref={bsrateRef} type="text" id="bs_rate" readOnly />
              <span className="unit_value">mmol/L</span>
            </div>
            {/* Blood Cholesterol Module Elements */}
            <div className="scan__Box">
              <label htmlFor='chol_rate'>Blood Cholesterol</label><br />
              <input ref={cholRateRef} type="text" id="chol_rate" readOnly />
              <span className="unit_value">mmol/L</span>
            </div>
          </div>
          <div className='scan__BtnCont'>
            <button id='skip_btn' className='skip_button' onClick={skipVideo} hidden={hideSkip}>Skip Video</button>
            <button id="start_stop_btn" value="1" hidden={hideActionBtn}>Start</button>
            &nbsp;&nbsp;<button onClick={onUploadHealthReadings} hidden={hideActionBtn}>Upload Report</button>
          </div>
          {/* Use to get Information about the modules */}
          <input hidden id='check_basic' readOnly />
          <input hidden id='check_bp' readOnly />
          <input hidden id='check_bs' readOnly />
          <input hidden id='check_bc' readOnly />
          <div className="scan__Box hide-div">
            <label htmlFor='diag_status' id='diag_label'>Health Status</label><br />
            <input id='diagnosis' type='text' ref={healthResultRef} /><input hidden type="text" id="diag_value" />
          </div>
        </div>
      </div>

      <p className='message-scan' hidden>Message : <span id='prog_dynamic'></span></p>
      {/* Toast Message Container */}
      <ToastContainer />
    </div>
  )

}

export default Scan
