import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Scan from './components/Scan';

function App() {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" element={<Scan />} />
      </Routes>
      </Router>
    </div>
  );
}
export default App;
